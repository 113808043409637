@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.spinner {
  filter: opacity(0.2);
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* chakra accordion */
.css-17mg6aq {
  border-top-width: 0px !important;
}

/* swiper photo css */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

/* scrollbar */
.app {
  width: 100vw;
  /* elements inside won't be affected by scrollbars */
}


#ultBtn::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
  top: 5;
  left: 0;
  background-color: #e84a36;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

#ultBtn:hover::after {
  transform: scaleX(1);
}

/* active navbars for previews */
#prev-links a.active {
  background-color: #f04c2f;
  color: white;
}
#prev-title a.active {
color: #f04c2f;
}
